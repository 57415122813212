var initializeSlick = function() {

    var $slickContainer = $('.testimonials .testimonials--list');

    // if ($('.testimonials--block').length <= 3) {
    //     return false;
    // }

    $slickContainer.slick({
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: '<span class="arrows prev"><span class="icon"><img src="'+templateBaseURL+'/public/images/slick-left.svg" alt="slick prev" /></span></span>',
        nextArrow: '<span class="arrows next"><span class="icon"><img src="'+templateBaseURL+'/public/images/slick-left.svg" alt="slick next" /></span></span>',
        responsive: [{
            breakpoint: 1350,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
            }
        }, {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        }]

    });
};

var initializeAccordion = function() {
    $('.faq--accordion').find('.accordion-toggle').click(function() {
        var $this = $(this);
        $this.next().slideToggle('fast', function() {
            var $icon = $this.find('.accordion-icon');
            if ($icon.hasClass('active')) {
                $icon.removeClass('active');
            } else {
                $icon.addClass('active');
            }
        });
    });
};

$(document).ready(function() {
    initializeSlick();
    initializeAccordion();

    $('.products-hover').mouseenter(function() {
        var $this = $(this);
        $this.find(".header-products").addClass('_show');
    }).mouseleave(function() {
        var $this = $(this);
        $this.find(".header-products").removeClass('_show');
    });

    $(".main-intro--move-below").click(function() {
        if ($(".product-blocks--container").length) {
            $('html, body').animate({
                    scrollTop: $(".product-blocks--container").offset().top
                },
                'slow');
        } else {
            $('html, body').animate({
                scrollTop: $(".audiofusion-desc").offset().top
            }, 'slow');
        }
    });

    $('.hamburger-menu').click(function(e) {

        var $this = $(this);

        $this.toggleClass("change").promise().done(function() {
            if ($this.hasClass('change')) {

                $('.nav-menu').addClass('show');

                $('html, body').css({
                    overflow: 'hidden',
                    height: '100%'
                });
            } else {

                $('.nav-menu').removeClass('show');

                $('html, body').css({
                    overflow: 'auto',
                    height: 'auto'
                });
            }
        });
    });

    $('.nav-menu .products-listing').click(function() {
        var $this = $(this);

        $this.find('.arrow').toggleClass('open');
        $this.find('.header-products').toggleClass('show');
    });

    $('.video--play-action').click(function() {
        var $playBtn = $('.video--play-action');
        var $embed = $('.video--embed');
        var $myVideo = $('.video--player')[0];

        if ($embed.css('display') === 'none') {
            $embed.show();
            $playBtn.hide();
            $myVideo.src += "?autoplay=1&showinfo=0&controls=0";
        }
    });

    $('.product-blocks--list li').hover(function() {
        var $this = $(this);
        var number = $this.data('number');
        var $images = $('.product-blocks--feature-images');
        $this.siblings().removeClass('_active');
        $this.addClass('_active');
        $images.find('img').removeClass('crossfade');
        $images.find('[data-img="' + number + '"]').addClass('crossfade');
    });

    $('.platform--placeholder').mouseenter(function() {
        var $this = $(this);
        var num = $this.data('pulse');
        var check = $('.no-animation').data('pulse');

        if (num == check) {
            return false;
        }

        $('.platform--placeholder').removeClass('no-animation');
        $this.addClass('no-animation');
        $('.platform--placeholder-message').fadeOut({ queue: false, duration: 'slow' });
        $('[data-pulse-msg="' + num + '"]').fadeIn({ queue: false, duration: 'slow' });
    });

});
